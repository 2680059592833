import { toast } from "react-toastify";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ordersuccess from "../../Assessts/Images/OrderSuccess.png";

import classes from "./Order.module.css";

import { path } from "../../Router/Routes.js";

import { storeActions } from "../../Store/StoreDetails/StoreSlice";

const OrderSuccess = () => {
  const { id, locationid } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");


  useEffect(() => {
    if(!B2BauthToken)
      {
        toast.error("Please login first!")
        navigate(path.login);
      }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
  }, [B2BauthToken, dispatch, id, locationid, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(
        path.orderHistory.replace(":id", id).replace(":locationid", locationid)
      );
    }, 15000);

    return () => clearTimeout(timer);
  }, [id, locationid, navigate]);

  return (
    <div className={`${classes.orderSuccessWrapper}`}>
      <div className={classes.orderSuccessContent}>
        <img src={ordersuccess} alt="order-placed-successfully" />

        <button
          className={`${classes.Btn} ${classes.BtnSuccess}`}
          onClick={() =>
            navigate(
              path.orderHistory
                .replace(":id", id)
                .replace(":locationid", locationid)
            )
          }
        >
          View Order History
        </button>
        <p className={classes.successMsg} >The final bill amount would be mentioned in the invoice.</p>
      </div>
    </div>
  );
};

export default OrderSuccess;
