import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ViewSVG } from "../../Assessts/SvgComponents/View.svg";
import { ReactComponent as OrderSVG } from "../../Assessts/SvgComponents/Order.svg";
import { ReactComponent as StoreSVG } from "../../Assessts/SvgComponents/StoreSVG.svg";
import { ReactComponent as PaymentSVG } from "../../Assessts/SvgComponents/Payment.svg";
import { ReactComponent as LogoutSVG } from "../../Assessts/SvgComponents/LogoutSvg.svg";

import classes from "./Header.module.css";

import TooltipComp from "./TooltipComp.js";

import { path } from "../../Router/Routes.js";

import { LogOutHandle } from "../../Store/Auth/AuthActions";


const SideHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pagename, setPagename] = useState("product");
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const storeId = useSelector((state) => state.store.storeId);
  const locationId = useSelector((state) => state.store.locationId);

  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [expandedSection, setExpandedSection] = useState("");
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  const handleAccordionClick = (section) => {
    setExpandedSection((prevSection) =>
      prevSection === section ? "" : section
    );
  };

  const LogOut = () => {
    if (B2BauthToken) {
      dispatch(LogOutHandle(B2BauthToken)).then((result) => {
        if (result.status === "success") {
          navigate(path.login);
          toast.success("Logged out successfully!");
        } else if (result.status === "error") {
          toast.error("Something is Wrong, Please try again later.", {
            autoClose: false,
          });
        }
      });
    }
  };

  const handleNavigation = (name, pathTemplate) => {
    setPagename(name);
    const fullPath = pathTemplate
      .replace(":id", storeId)
      .replace(":locationid", locationId);
    navigate(fullPath);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const itemName = pathSegments[pathSegments.length - 1];
    setPagename(itemName);
  }, [location]);


  return (
    <>
      <div className={classes.mainContainer}>
        <div
          className={`${classes.SideBarWrapper} ${
            isSidebarOpen ? classes.open : classes.closed
          }`}
        >
          <div className={classes.SideBarContainer}>
            <button
              onClick={toggleSidebar}
              className={`  ${
                isSidebarOpen ? classes.opentoggleButton : classes.toggleButton
              }  `}
            >
              <i className="bi bi-list"></i>
            </button>
            <div className={classes.SideBarContainerScroll}>
              <div classname="accordion" id="accordionExample">
                <div className={`${classes.AccordionSection} accordion-item `}>
                  <p classname="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      onClick={() => handleAccordionClick("order")}
                      aria-expanded={expandedSection === "order"}
                      aria-controls="collapseOne"
                      // data-toggle="tooltip"
                      // data-placement="right"
                      // title="Order"
                    >
                      <TooltipComp tooltipText="Order" placement="right">
                        <p>
                          <span>
                            <OrderSVG />
                          </span>
                          {isSidebarOpen && <span>Order</span>}
                        </p>
                      </TooltipComp>
                      {isSidebarOpen && (
                        <span className={classes.SideBarArrow}>
                          <i
                            className={`bi ${
                              expandedSection === "order"
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          ></i>
                        </span>
                      )}
                    </button>
                  </p>
                  <div
                    id="collapseOne"
                    className={`${classes.show} accordion-collapse collapse show `}
                    data-bs-parent="#accordionExample"
                  >
                    <div classname="accordion-body">
                      <ul className={classes.SideBarList}>
                        <li
                          onClick={() =>
                            handleNavigation("purchaseOrder", path.createOrder)
                          }
                          className={
                            pagename === "order"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                        >
                          <TooltipComp
                            tooltipText="Create Order"
                            placement="right"
                          >
                            <span>
                              {" "}
                              <i className="bi bi-graph-up-arrow"></i>
                            </span>
                            {isSidebarOpen && <span>Create Order</span>}
                          </TooltipComp>
                        </li>

                        <li
                          onClick={() =>
                            handleNavigation("orderHistory", path.orderHistory)
                          }
                          className={
                            pagename === "orderHistory"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                        >
                          <TooltipComp
                            tooltipText="Order History"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-clock-history"></i>
                            </span>
                            {isSidebarOpen && <span>Order History</span>}
                          </TooltipComp>
                        </li>

                        <li
                          onClick={() =>
                            handleNavigation(
                              "Invoice Details",
                              path.invoiceReport
                            )
                          }
                          className={
                            pagename === "invoiceReport"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                        >
                          <TooltipComp
                            tooltipText="Invoice Details"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-reception-4"></i>
                            </span>
                            {isSidebarOpen && <span>Invoice History</span>}
                          </TooltipComp>
                        </li>

                        {/* <li
                          onClick={() =>
                            navigate(
                              path.inProgress
                                .replace(":id", storeId)
                                .replace(":locationid", locationId)
                            )
                          }
                          
                        >
                          <TooltipComp
                            tooltipText="Delivery Tracker"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-truck"></i>
                            </span>
                            {isSidebarOpen && <span>Delivery Tracker</span>}
                          </TooltipComp>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.AccordionSection} accordion-item view`}
                >
                  <p classname="accordion-header">
                    <button
                      className="accordion-button collapsed "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      onClick={() => handleAccordionClick("view")}
                      aria-expanded={expandedSection === "view"}
                      aria-controls="collapseTwo"
                    >
                      <TooltipComp tooltipText="View" placement="right">
                        <p>
                          <span>
                            <ViewSVG />
                          </span>
                          {isSidebarOpen && <span>Products</span>}
                        </p>
                      </TooltipComp>
                      {isSidebarOpen && (
                        <span className={classes.SideBarArrow}>
                          <i
                            className={`bi ${
                              expandedSection === "view"
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          ></i>
                        </span>
                      )}
                    </button>
                  </p>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div classname="accordion-body">
                      <ul className={classes.SideBarList}>
                        <li
                          onClick={() =>
                            handleNavigation("product", path.productDetail)
                          }
                          className={
                            pagename === "product"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Products"
                        >
                          <TooltipComp tooltipText="Products" placement="right">
                            <span>
                              <i className="bi bi-box-seam"></i>
                            </span>
                            {isSidebarOpen && <span>Products</span>}
                          </TooltipComp>
                        </li>

                        {/* <li
                          onClick={() =>
                            navigate(
                              path.inProgress
                                .replace(":id", storeId)
                                .replace(":locationid", locationId)
                            )
                          }
                          
                        >
                          <TooltipComp
                            tooltipText="Promotions"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-percent"></i>
                            </span>
                            {isSidebarOpen && <span>Promotions</span>}
                          </TooltipComp>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`${classes.AccordionSection} accordion-item `}>
                  <p className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      onClick={() => handleAccordionClick("others")}
                      aria-expanded={expandedSection === "others"}
                      aria-controls="collapseThree"
                    >
                      <TooltipComp tooltipText="Statement" placement="right">
                        <p>
                          <span>
                            <PaymentSVG />
                          </span>
                          {isSidebarOpen && <span>Statement</span>}
                        </p>
                      </TooltipComp>
                      {isSidebarOpen && (
                        <span className={classes.SideBarArrow}>
                          <i
                            className={`bi ${
                              expandedSection === "others"
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          ></i>
                        </span>
                      )}
                    </button>
                  </p>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div classname="accordion-body">
                      <ul className={classes.SideBarList}>
                        {/* <li
                          // onClick={() =>
                          //   handleNavigation("payment", path.payment)
                          // }
                          onClick={() =>
                            navigate(
                              path.inProgress
                                .replace(":id", storeId)
                                .replace(":locationid", locationId)
                            )
                          }
                          
                        >
                          <TooltipComp tooltipText="Payments" placement="right">
                            <span>
                              <i className="bi bi-credit-card-2-front"></i>
                            </span>
                            {isSidebarOpen && <span>Payments</span>}
                          </TooltipComp>
                        </li> */}
                        <li
                          onClick={() =>
                            handleNavigation("ledger", path.ledger)
                          }
                          className={
                            pagename === "ledger"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Ledger"
                        >
                          <TooltipComp tooltipText="Ledger" placement="right">
                            <span>
                              <i className="bi bi-receipt"></i>
                            </span>
                            {isSidebarOpen && <span>Ledger</span>}
                          </TooltipComp>
                        </li>
                        <li
                          onClick={() =>
                            handleNavigation("outstanding", path.outstanding)
                          }
                          className={
                            pagename === "outstanding"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                        >
                          <TooltipComp
                            tooltipText="Outstanding"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-reception-4"></i>
                            </span>
                            {isSidebarOpen && <span>Outstanding</span>}
                          </TooltipComp>
                        </li>

                        <li
                          onClick={() =>
                            navigate(
                              path.creditList
                                .replace(":id", storeId)
                                .replace(":locationid", locationId)
                            )
                          }
                          className={
                            pagename === "customerCreditList"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                        >
                          <TooltipComp
                            tooltipText="Credit Note / Debit Note"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-journal-x"></i>
                            </span>
                            {isSidebarOpen && <span>Credit Note</span>}
                          </TooltipComp>
                        </li>
                        <li
                          onClick={() =>
                            navigate(
                              path.debitList
                                .replace(":id", storeId)
                                .replace(":locationid", locationId)
                            )
                          }
                          className={
                            pagename === "customerDebitList"
                              ? `${classes.active} titleText`
                              : "titleText"
                          }
                        >
                          <TooltipComp
                            tooltipText="Credit Note / Debit Note"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-journal-x"></i>
                            </span>
                            {isSidebarOpen && <span>Debit Note</span>}
                          </TooltipComp>
                        </li>
                        {/* <li
                          onClick={() =>
                            navigate(
                              path.inProgress
                                .replace(":id", storeId)
                                .replace(":locationid", locationId)
                            )
                          }
                        >
                          <TooltipComp
                            tooltipText="Purchase Return"
                            placement="right"
                          >
                            <span>
                              <i className="bi bi-box-seam"></i>
                            </span>

                            {isSidebarOpen && <span> Purchase Return</span>}
                          </TooltipComp>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.AccordionSection}>
                <button
                  onClick={() => handleNavigation("Store List", path.storeList)}
                  className={
                    pagename === "Store List"
                      ? `${classes.active} titleText`
                      : `${classes.backStore} titleText`
                  }
                >
                  <TooltipComp tooltipText="Back to Stores" placement="right">
                    <p>
                      <span>
                        <StoreSVG />
                      </span>
                      {isSidebarOpen && <span>Back to Stores</span>}
                    </p>
                  </TooltipComp>
                </button>
              </div>

              {B2BauthToken && (
                <div
                  className={`${classes.AccordionSection} ${classes.logout} `}
                  onClick={LogOut}
                >
                  <button>
                    <TooltipComp tooltipText="Logout" placement="right">
                      <p>
                        <span>
                          <LogoutSVG />
                        </span>

                        {isSidebarOpen && <span>Logout</span>}
                      </p>
                    </TooltipComp>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${classes.RightContent} ${
            isSidebarOpen ? classes.sidebarOpen : classes.sidebarClosed
          }`}
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

export default SideHeader;
