import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import styles from "../order/Order.module.css";
// import BatchAccordian from "./BatchAccordian";

const OrderlistPopup = ({
  showModal,
  handleCloseModal,
  selectedProduct,
  quantityInputRef,
  quantity,
  handleQuantityChange,
  biilingRate,
  remark,
  handleRemarkChange,
  handleAddToOrderList,
  batchDetails,
  setProductType
}) => {

  // const barcodes = batchDetails?.Locations?.[0]?.Barcodes || [];

  const handleTypeChange = (e)=>{
    setProductType(e.target.value);
  }

  useEffect(() => {
    if (selectedProduct?.SellLoose) {
      setProductType(selectedProduct.Unit1);
    }
  // eslint-disable-next-line
  }, [selectedProduct]);

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={styles.modalTitle}>Create Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <form>
              <div className="mb-3">
                <h5 className={styles.productName}>
                  {selectedProduct.ERPProductName}
                </h5>
              </div>
              <div className={`${styles.QtyFieldBox} mb-3`}>
                <label className="form-label">Select Quantity</label>
                <div className={`${styles.QtyField}`}>
                  <input
                    ref={quantityInputRef}
                    type="text"
                    placeholder="Enter Qty..."
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  {selectedProduct.SellLoose && (
                    <select className={`${styles.dropDownQty} form-select`} onChange={handleTypeChange}>
                      <option value={selectedProduct.Unit1}>{selectedProduct.Unit1}</option>
                      <option value={selectedProduct.Unit2}>{selectedProduct.Unit2}</option>
                    
                    </select>
                  )}
                </div>
              </div>

              <div className={styles.orderAmountList}>
                <div className="mb-2 d-flex justify-content-between">
                  <h6>MRP</h6>
                  <h6>
                    <b>{selectedProduct?.MRP?.toFixed(2)}</b>
                  </h6>
                </div>

                <div className=" mb-2 d-flex justify-content-between">
                  <h6>Rate</h6>
                  <h6>
                    <b>
                      {biilingRate === "M"
                        ? selectedProduct?.MRP?.toFixed(2)
                        : biilingRate === "S"
                        ? selectedProduct?.SaleRate?.toFixed(2)
                        : biilingRate === "T"
                        ? selectedProduct?.TradeRate?.toFixed(2)
                        : biilingRate === "D"
                        ? selectedProduct?.DistributionRate?.toFixed(2)
                        : biilingRate === "P"
                        ? selectedProduct?.PurchaseRate?.toFixed(2)
                        : biilingRate === "C"
                        ? selectedProduct?.CostRate?.toFixed(2)
                        : "Rate not available"}
                    </b>
                  </h6>
                </div>
                <div className="d-flex flex-column mt-1">
                  <h6>Remark:</h6>
                  <textarea
                    className="p-2"
                    value={remark}
                    onChange={handleRemarkChange}
                  ></textarea>
                </div>
              </div>
              <div
                className={`${styles.TotalAmount} mb-3 d-flex justify-content-between`}
              >
                <h6>Total Amount</h6>
                <h6>
                  <b>
                    {(
                      quantity *
                      (biilingRate === "M"
                        ? selectedProduct?.MRP
                        : biilingRate === "S"
                        ? selectedProduct?.SaleRate
                        : biilingRate === "T"
                        ? selectedProduct?.TradeRate
                        : biilingRate === "D"
                        ? selectedProduct?.DistributionRate
                        : biilingRate === "P"
                        ? selectedProduct?.PurchaseRate
                        : biilingRate === "C"
                        ? selectedProduct?.CostRate
                        : 0)
                    ).toFixed(2)}
                  </b>
                </h6>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className={styles.Btn}
                  onClick={(e) => handleAddToOrderList(e)}
                >
                  Add
                </button>
              </div>
            </form>
          )}

          {/* <BatchAccordian barcodes={barcodes} /> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderlistPopup;
