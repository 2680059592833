import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { path } from "../../Router/Routes.js";

import styles from "./StoreList.module.css";
import classes from "../productDetail/ProductDetail.module.css";

import NoStore from "../../Assessts/Images/NoStore.png";

import Signup from "../Login/Signup";
import MainHeader from "../header/MainHeader";
import Header from "../../reusableComponents/Header";
import Loader from "../../reusableComponents/Loader/Loader";

import {
  getAllStoreListHanlder,
  getStoreByIdHandle,
  getStoreLocationHanlder,
  getStoreStatusHanlder,
} from "../../Store/StoreDetails/StoreActions";
import { getProfileHandler } from "../../Store/Auth/AuthActions";

import StoreLocationsModal from "./StoreLocationsModal.js";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import SwilUnifyBannerComponent from "./SwilUnifyBannerComponent.js";

const StoreList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [storeList, setStoreList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [showSignup, setShowSignup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationpopup, setLocationpopup] = useState(false);
  const [storelocid, setStorelocid] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showSwilUnifyBAnner, setShowSwilUnifyBAnner] = useState(false);

  const closeSummaryModal = () => {
    setLocationpopup(false);
  };

  useEffect(() => {
    if (!B2BauthToken) {
      navigate(path.login);
      toast.error("Please login first!");
    }
  }, [B2BauthToken, navigate]);

  useEffect(() => {
    let displayPormotionBanner = localStorage.getItem("justLoggenIn");
    if (displayPormotionBanner) {
      setShowSwilUnifyBAnner(true);
      localStorage.removeItem("justLoggenIn");
    }
  }, []);

  const handleStoreSelect = (id, mongoid) => {
    setStoreId(id);
    if (B2BauthToken) {
      dispatch(getProfileHandler(B2BauthToken, id)).then((result) => {
        if (result.status === "success") {
          if (
            result.data.data &&
            result.data.data[0]?.b2bVerified === "Approved"
          ) {
            dispatch(getStoreByIdHandle(B2BauthToken, mongoid)).then(
              (result) => {
                if (result.status === "success") {
                  dispatch(storeActions.setStoreHandler(result?.data));
                  dispatch(getStoreLocationHanlder(id)).then((result) => {
                    setLocations(result.data);

                    if (result.data.length === 1) {
                      let loc = result.data[0].LocationId;
                      navigate(
                        path.productDetail
                          .replace(":id", id)
                          .replace(":locationid", loc)
                      );
                    } else if (result.data.length === 0) {
                      setPopupMessage(
                        "No locations found. Please try contacting the administrator."
                      );
                      setShowPopup(true);
                    } else {
                      setStorelocid(id);
                      setLocationpopup(true);
                    }
                  });
                }
              }
            );
          } else if (
            result.data.data &&
            result.data.data[0]?.b2bVerified === "Pending"
          ) {
            toast.error("Wait for approval");
          } else if (
            result.data.data &&
            result.data.data[0]?.b2bVerified === "Rejected"
          ) {
            toast.error("Approval rejected");
          } else if (result.data.registered === false) {
            setShowSignup(true);
          }
        }
      });
    } else {
      toast.error("Please login first!");
      navigate(path.login);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    setLoading(true);

    dispatch(getStoreStatusHanlder(B2BauthToken)).then((result) => {
      if (result.status === "success") {
        setStatusList(result.data);

        dispatch(getAllStoreListHanlder()).then((result) => {
          if (result.status === "success") {
            setStoreList(result.data);

            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        dispatch(getAllStoreListHanlder()).then((result) => {
          if (result.status === "success") {
            setStoreList(result.data);

            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    });
  }, [dispatch, B2BauthToken]);

  const statusMap = statusList.reduce((acc, status) => {
    acc[status.storeId] = status.status;
    return acc;
  }, {});

  const handleSignupSuccess = () => {
    setSignupSuccess(true);
  };

  useEffect(() => {
    if (signupSuccess) {
      dispatch(getAllStoreListHanlder()).then((result) => {
        if (result.status === "success") {
          setStoreList(result.data);
          setSignupSuccess(false);
        }
      });
      dispatch(getStoreStatusHanlder(B2BauthToken)).then((result) => {
        if (result.status === "success") {
          setStatusList(result.data);
        }
      });
    }
  }, [signupSuccess, dispatch, B2BauthToken]);

  const closeBanner = () => {
    setShowSwilUnifyBAnner(false);
  };

  return (
    <>
      <MainHeader />
      <Header pagename={"Store List"} />

      {showSwilUnifyBAnner && <SwilUnifyBannerComponent closeBanner={closeBanner} />}

      {showSignup && (
        <Signup
          storeId={storeId}
          onClose={() => setShowSignup(false)}
          onSignupSuccess={handleSignupSuccess}
        />
      )}

      {loading === true ? (
        <div className={`${styles.PageWrapper}`}>
          <Loader />
        </div>
      ) : (
        <div className={`${styles.StorelistPage}`}>
          {storeList && storeList.length > 0 ? (
            <>
              <div className={` ${styles.StorelistTable}`}>
                <table className={`${classes.TableComponent}`}>
                  <thead className={`${classes.TableComponentHeader}`}>
                    <tr>
                      <th className={` ${styles.tableWidth}`} scope="col">
                        STORE NAME
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        STORE URL
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        ADDRESS
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        STATUS
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        BUSINESS DETAIL
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        EMAIL
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        MOBILE NUMBER
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        SUPPORT NUMBER
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        WEBSITE
                      </th>
                      <th className={`${styles.tableWidth}`} scope="col">
                        OPENING HOURS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {storeList.map((store, index) => (
                      <tr
                        className={`${styles.tableRow}`}
                        key={index}
                        onClick={() =>
                          handleStoreSelect(store.StoreId, store._id)
                        }
                      >
                        <td className={`${styles.tableWidth}`}>
                          {store.StoreName}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.StoreURL}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.address}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {statusMap[store.StoreId] || "Unknown"}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.BusinessDetail}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.Email}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.MobileNumber}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.SupportNumber}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.Website}
                        </td>
                        <td className={`${styles.tableWidth}`}>
                          {store.OpeningHours}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className={`${styles.NoImageContainer}`}>
              <img src={NoStore} alt="no-store-found" />
            </div>
          )}
        </div>
      )}

      <Modal
        className={`${classes.orderSummaryModal} order-summary-box`}
        show={showPopup}
        onHide={handleClosePopup}
        centered
      >
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={classes.modalTitle}>
            No locations listed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{popupMessage}</p>
        </Modal.Body>
      </Modal>

      <StoreLocationsModal
        locationpopup={locationpopup}
        closeSummaryModal={closeSummaryModal}
        locations={locations}
        storelocid={storelocid}
      />
    </>
  );
};

export default StoreList;
