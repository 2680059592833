import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import classes from "./Order.module.css";

import AddAddressModal from "./AddAddressModal";
import Loader from "../../reusableComponents/Loader/Loader";

const CreateOrderPopup = ({
  ordersummaryVisible,
  closeSummaryModal,
  selectedLocation,
  orderType,
  handleOrderTypeChange,
  summaryValue,
  setOrderRemark,
  calculateTotalAmount,
  handleSameAsBilling,
  selectedAddress,
  handleAddressChange,
  shippingAddresses,
  loading,
  orderHandle,
  setShippingAddresses
}) => {
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);

  const addressModal = () =>{
    setShowNewAddressModal(true);
  }


  return (
    <>
      <Modal
        className={`${classes.orderSummaryModal} order-summary-box`}
        show={ordersummaryVisible}
        onHide={closeSummaryModal}
        centered
      >
        <Modal.Header closeButton className={`${classes.OrderSummHeader} p-2`}>
          <Modal.Title className={classes.modalTitle}>
            Order Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.orderModalBody}>
          <div className={classes.ordersummaryMain}>
            <div className={classes.ordersummaryLeft}>
              <div className={`${classes.selectedPlace} mb-2 d-flex`}>
                <h4>{selectedLocation?.LocationName}</h4>
              </div>
              <div className={`${classes.selectedLocation} mt-3 mb-2 d-flex`}>
                <h6>Mobile:</h6>
                <p>{selectedLocation ? selectedLocation.phone : ""}</p>
              </div>

              <div className={`${classes.selectedLocation} mb-2 d-flex`}>
                <h6>Email:</h6>
                <p>{selectedLocation ? selectedLocation.email : ""}</p>
              </div>
              <div className={`${classes.selectedLocation} mb-2 d-flex`}>
                <h6>Address:</h6>
                <p>{selectedLocation ? selectedLocation.address : ""}</p>
              </div>

              <div className={`${classes.selectedLocation} mt-5 mb-4`}>
                <h6 className="mb-2">Order Type:</h6>

                {selectedLocation?.b2bDispatchOption === "Pick up" && (
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      id="Pick up"
                      name="orderType"
                      value="P"
                      checked={orderType === "P"}
                      onChange={handleOrderTypeChange}
                    />
                    <label className={`${classes.RadioPOint} mx-1`}  htmlFor="Pick up">
                      Pickup
                    </label>
                  </div>
                )}

                {selectedLocation?.b2bDispatchOption === "Delivery" && (
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      id="Delivery"
                      name="orderType"
                      value="D"
                      checked={orderType === "D"}
                      onChange={handleOrderTypeChange}
                    />
                    <label className={`${classes.RadioPOint} mx-1`}  htmlFor="Delivery">
                      Delivery
                    </label>
                  </div>
                )}

                {selectedLocation?.b2bDispatchOption === "Both" && (
                  <div className="d-flex gap-3 align-items-center">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="Delivery"
                        name="orderType"
                        value="D"
                        checked={orderType === "D"}
                        onChange={handleOrderTypeChange}
                      />
                      <label className={`${classes.RadioPOint} mx-1`}  htmlFor="Delivery">
                        Delivery
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="Pick up"
                        name="orderType"
                        value="P"
                        checked={orderType === "P"}
                        onChange={handleOrderTypeChange}
                      />
                      <label className={`${classes.RadioPOint} mx-1`}  htmlFor="Pick up">
                        Pickup
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.ordersummaryRight}>
              <>
                {summaryValue && (
                  <form>
                    <div className={classes.orderAmountList}>
                      <div className={`${classes.RadioPOint} mb-2 d-flex justify-content-between`}  >
                        <h6>No. of Products</h6>
                        <h6>
                          <b>{summaryValue.length}</b>
                        </h6>
                      </div>
                    </div>
                    <div
                      className={`${classes.TotalAmount} mb-3 d-flex justify-content-between`}
                    >
                      <h6>Total Amount</h6>
                      <h6>
                        <b>₹{calculateTotalAmount(summaryValue).toFixed(2)}</b>
                      </h6>
                    </div>

                    <div
                      className={`${classes.RemarkBox} mb-2 d-flex flex-column`}
                    >
                      <label>Remark</label>
                      <textarea
                        className="p-2"
                        placeholder="Write your text here...."
                        onChange={(e) => setOrderRemark(e.target.value)}
                      ></textarea>
                    </div>
                  </form>
                )}
              </>
              <>
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3 className={classes.Shippingtitle}>Shipping Address:</h3>
                    <div className="d-flex align-items-center gap-1 checkbox-div">
                      <input
                        type="checkbox"
                        id="sameAsBilling"
                        className="form-check-input m-0"
                        onChange={handleSameAsBilling}
                      />
                      <label
                        htmlFor="sameAsBilling"
                        className={`${classes.billingCheck} form-check-label`}
                      >
                        Same as Billing Address
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="shippingAddressDropdown"
                      className={`${classes.selectShip} form-label d-flex gap-2 align-items-center`}
                    >
                      Select Shipping Address:{" "}
                      <p className={classes.addnewaddress} onClick={addressModal} >Add new address</p>
                    </label>
                    <select
                      id="shippingAddressDropdown"
                      className="form-select"
                      value={selectedAddress?.Address}
                      onChange={handleAddressChange}
                    >
                      <option  className={classes.optionwidth} value="">Select an address</option>
                      {shippingAddresses?.map((address, index) => (
                        <option
                          key={index}
                          className={classes.optionwidth}
                          value={address.PKID}
                        >
                          {address.Name + " - " + address.Address}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedAddress !== "" && (
                    <div className="mt-1 p-2">
                      <p className={`${classes.nametu} d-flex gap-2`}>
                        <b>Name:</b> {selectedAddress?.Name}
                      </p>
                      <p className={`${classes.nametu} d-flex gap-2`}>
                        <b>Phone:</b> {selectedAddress?.Phone1}
                      </p>
                      <p className={`${classes.nametu} d-flex gap-2`}>
                        <b>Address:</b> {selectedAddress?.Address}
                      </p>
                      <p className={`${classes.nametu} d-flex gap-2`}>
                        <b>Pincode:</b> {selectedAddress?.Pincode}
                      </p>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={classes.createorderfooter}>
          <p className={classes.successMsgModal}>
            The final bill amount would be mentioned in the invoice.
          </p>
          <div className="d-flex justify-content-end">
            <button className={classes.Btn} onClick={(e) => orderHandle(e)}>
              {loading === true ? <Loader /> : " Confirm Order"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <AddAddressModal
        show={showNewAddressModal}
        onClose={() => setShowNewAddressModal(false)}
        setShippingAddresses={setShippingAddresses}
      />
    </>
  );
};

export default CreateOrderPopup;
