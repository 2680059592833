import { CustomerInstance } from "../../Router/AxiosInstance";
import { shipActions } from "./ShippingAddressSlice";

export const getShippingAddressList = (storeid, pkid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      message: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/shipping/getShippingDetails/${storeid}/${pkid}`,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = response.data.status;
          result.message = response.data.message;
          result.data = response.data.data;
          dispatch(shipActions.setShippingAddressHandler(response.data.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  };
};

export const getLocalityList = (storeid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      message: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/shipping/getLocalityList/${storeid}`,
    };

    await CustomerInstance(config).then(function (response) {
      if (response.data.status === "success") {
        result.status = response.data.status;
        result.message = response.data.message;
        result.data = response.data.data;
        dispatch(shipActions.setLocalityList(response.data.data));
      }
    });
  };
};

export const getStationList = (storeid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      message: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/shipping/getStationList/${storeid}`,
    };

    await CustomerInstance(config).then(function (response) {
      if (response.data.status === "success") {
        result.status = response.data.status;
        result.message = response.data.message;
        result.data = response.data.data;
        dispatch(shipActions.setStationList(response.data.data));
      }
    });
  };
};

export const createNewShippingAddress = (storeid, pkid, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      message: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/shipping/addShippingAddress/${storeid}/${pkid}`,
      data: data,
    };

    await CustomerInstance(config).then(function (response) {
      if (response.data.status === "success") {
        result.status = response.data.status;
        result.message = response.data.message;
      }
    });

    return result;
  };
};
