import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import swilmart from "../../Assessts/Images/swilmart.png";

import { ReactComponent as FacebookIcon } from "../../Assessts/SvgComponents/FaceBookIcon.svg";
import { ReactComponent as InstagramIcon } from "../../Assessts/SvgComponents/InstagramIcon.svg";
import { ReactComponent as TwitterIcon } from "../../Assessts/SvgComponents/TwitterIcon.svg";

import classes from "./Header.module.css";
import styles from "../../reusableComponents/Header.module.css";

import CustomDropdown from "../../reusableComponents/CustomDropdown/CustomDropdown.js";

import { path } from "../../Router/Routes.js";

import {
  LogOutHandle,
  LoggedInHandler,
  getProfileHandler,
} from "../../Store/Auth/AuthActions";
import {
  getStoreByIdHandle,
  getStoreLocationHanlder,
} from "../../Store/StoreDetails/StoreActions";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";

import { orderListActions } from "../../Store/OrderList/OrderListSlice.js";

const MainHeader = () => {
  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const profileData = useSelector((state) => state.orderList.profileData);
  const locationId = useSelector((state) => state.store.locationId);
  const LogData = useSelector((state) => state.auth.LogData);
  const [loggedin, setLoggedin] = useState(false);
  const [storedata, setStoredata] = useState(null);
  const [allLocation, setAllLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);


  const LogOut = () => {
    if (B2BauthToken) {
      dispatch(LogOutHandle(B2BauthToken)).then((result) => {
        if (result.status === "success") {
          navigate(path.login);
          toast.success("Logged out successfully!");
        } 
        else if (result.status === "error") {
          toast.error("Something is Wrong, Please try again later.", {
            autoClose: false,
          });
        }
      });
    }
  };

  const backtoStore = () => {
    navigate(path.storeList);
  };

  useEffect(() => {
    dispatch(storeActions.setStoreIdHandler(id));
   

    if (B2BauthToken) {
      dispatch(LoggedInHandler(B2BauthToken)).then((result) => {
        if (result.status === "success") {
          setLoggedin(true);
          dispatch(getProfileHandler(B2BauthToken, id)).then((result) => {
            if (result.status === "success") {
              if (result.data.registered === true) {
                dispatch(orderListActions.setProfileData(result.data.data[0]));
              }
            }
              });
          if (B2BauthToken && id) {
            dispatch(getStoreByIdHandle(B2BauthToken, id)).then((result) => {
              if (result.status === "success") {
                setStoredata(result.data);
              }
             });

            dispatch(getStoreLocationHanlder(id)).then((result) => {
              if (result.status === "success") {
                setAllLocation(result.data);
                const defaultLocation = result.data.find(
                  (loc) => loc.LocationId.toString() === locationid
                );
                setSelectedLocation(defaultLocation);
              } 
             });
          }

      
        } else {
          navigate(path.login);
        }
      });
    }
  }, [B2BauthToken, navigate, dispatch, id, locationid]);

  const handleLocationChange = (locId) => {
    const currentPath = window.location.pathname;

    const selectedLoc = allLocation.find((loc) => loc._id === locId);
    setSelectedLocation(selectedLoc);
   
    const pathParts = currentPath.split("/");
    pathParts[1] = id; 
    pathParts[2] = selectedLoc.LocationId; 
    const newUrl = pathParts.join("/");
    

    window.history.replaceState(null, "", newUrl);
    window.location.reload();
  };


  return (
    <div className={`${classes.MainHeaderWrapper}`}>
      <div className={`${classes.MainHeaderWr} d-flex  align-items-center`}>
        <div className={`${classes.MainHeaderLogo}`} onClick={backtoStore}>
          <img
            src={storedata?.Logo ? storedata?.Logo : swilmart}
            alt="swilmart-logo"
          />
        </div>

        {location.pathname === "/store-list" ? (
          ""
        ) : (
          <CustomDropdown
            label="Select Location"
            options={allLocation?.map((loc) => ({
              label:
                loc.address && (loc.address !== "" || loc.address !== "null")
                  ? `${loc.LocationName} - ${loc.address}`
                  : `${loc.LocationName}`,
              value: loc._id,
            }))}
            value={selectedLocation ? selectedLocation._id : ""}
            onChange={(value) => handleLocationChange(value)}
          />
        )}
      </div>

      <div className={`${classes.searchContainer} `}>
        {/* <input
          type="search"
          disabled
          className={`${classes.inputSearch} form-control `}
          aria-label="Search"
          placeholder="What are you looking for...."
        />
        <button className={`${classes.searchButton}`}>
          <span>
            <i className="bi bi-search"></i>
            <p>Search</p>
          </span>
        </button> */}
        <p>
          {storedata?.DisplayName
            ? storedata?.DisplayName
            : storedata?.StoreName}
        </p>
      </div>

      <div className={`${classes.ProfileInfo}`}>
        {location.pathname === "/store-list" ? (
          ""
        ) : (
          <div>
            <button
              className={`${styles.Btn}`}
              onClick={() =>
                navigate(
                  path.createOrder
                    .replace(":id", id)
                    .replace(":locationid", locationid)
                )
              }
            >
              {" "}
              <i class="bi bi-cart"></i>
               <span className="cartText">Go to cart</span>
            </button>
          </div>
        )}
        <div
          className={`${classes.ProfileButton}`}
          {...(loggedin
            ? { "data-bs-toggle": "dropdown", "aria-expanded": "false" }
            : {
                onClick: () => {
                  navigate(path.login);
                },
              })}
        >
          <span className={`${classes.avatarBox}`}>
            <i className="bi bi-person"></i>
          </span>
          <div className={`${classes.ProfileMainDropdown} dropdown`}>
          <ul className={`${classes.profileDropdown} dropdown-menu ${location.pathname === "/store-list" ? classes.profileSToreDropdown  : ""}`}>

              {location.pathname === "/store-list" ? (
                <div className={` ${classes.profilelogheader}`}>
                  <div className={`${classes.profileName}`}>
                    <h5>Hello</h5>

                    {LogData?.ContactNumber && (
                      <p> 
                        <span>{LogData?.ContactNumber}</span>
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                profileData && (
                  <>
                    <div className={`${classes.profileHeader}`}>
                      {location.pathname !== "/store-list" ? (
                        <span
                          className={`${classes.EditIcon}`}
                          onClick={() =>
                            navigate(
                              path.profileCustomer
                                .replace(":id", id)
                                .replace(":locationid", locationId)
                            )
                          }
                        >
                          <i class="bi bi-pencil"></i>
                        </span>
                      ) : (
                        ""
                      )}

                      <div className={`${classes.profileName}`}>
                        <h5>
                          {profileData?.CompanyName
                            ? profileData?.CompanyName
                            : "Guest"}
                        </h5>
                        <p>
                          <span>{profileData?.ContactNumber}</span>
                        </p>
                        <p>
                          <span>{profileData?.Email}</span>
                        </p>
                      </div>
                    </div>
                  </>
                )
              )}

              {storedata && (
                <>
                  <div className={`${classes.profileHeaderStore}`}>
                    <div className={`${classes.CompanyDetails}`}>
                      <div className={`${classes.profileop}`}>
                        <h5>Store Details</h5>
                        <i class="bi bi-building-fill-check"></i>
                      </div>

                      <div className={`${classes.profileCustomerInfo}`}>
                        {storedata?.Logo && (
                          <div className={`${classes.profileLogo}`}>
                            <img src={storedata?.Logo} alt="logo" />
                          </div>
                        )}
                        <h5>{storedata?.StoreName}</h5>

                        <p>
                          <span>{storedata?.address}</span>
                        </p>
                        <p>
                          <span>{storedata?.station}</span>
                        </p>
                        <p>
                          <span>{storedata?.state}</span>
                        </p>
                        <p>
                          <span>{storedata?.Email}</span>
                        </p>
                        <p>
                          <span>{storedata?.MobileNumber}</span>
                        </p>
                        <p>
                          <span>{storedata?.SupportNumber}</span>
                        </p>
                        <div className={`${classes.socialLInks}`}>
                          <span
                            onClick={() =>
                              window.open(storedata.FacebookURL, "_blank")
                            }
                          >
                            {/* <i className="bi bi-facebook"></i> */}
                            <FacebookIcon />
                          </span>
                          <span
                            onClick={() =>
                              window.open(storedata.TwitterURL, "_blank")
                            }
                          >
                            {/* <i className="bi bi-twitter"></i> */}
                            <TwitterIcon />
                          </span>
                          <span
                            onClick={() =>
                              window.open(storedata.InstagramURL, "_blank")
                            }
                          >
                            {/* <i className="bi bi-instagram"></i> */}
                            <InstagramIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <li className={`${classes.profilelogOut} ${location.pathname === "/store-list" ? classes.profileLogHeight : ""}`} onClick={LogOut}>
                <span>
                  <i class="bi bi-box-arrow-left"></i>
                </span>
                <span>Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
