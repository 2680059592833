import { ProductInstance } from "../../Router/AxiosInstance";
import { productActions } from "./ProductSlice";

export const getProductByStoreHandler = (params) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data:[],
    };

    if (params.active === "all") {
      params.active = "";
    }
    if (params.MfgGroupName === "Not Listed" || params.MfgGroupName === "all") {
      params.MfgGroupName = "";
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomers/product/getAllProducts?ClientRegID=${params.ClientRegID}&LocationID=${params.LocationID}&IsAllProduct=true&page=${params.page}&limit=${params.limit}&active=${params.active}&search=${params.search}&MfgGroupName=${params.MfgGroupName}`,
    };
    try {
      const response = await ProductInstance(config);

      if (response.data.status === "success") {
        result.status = "success";
        result.data = response.data.data;
        result.totalItems = response.data.totalItems;
        result.totalPages = response.data.totalPages;
        result.totalActiveItems = response.data.totalActiveItems;
        result.totalInActiveItems = response.data.totalInActiveItems;
        result.uniqueGroupNames = response.data.uniqueGroupNames;
        result.resultProd = response.data.resultProd;
        result.total_Items = response.data.total_Items;

        dispatch(productActions.setProductListHandler(response.data.data));
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            result.status = "success";
            result.msg = "Bad Request! Please check your input parameters.";
            result.data = [];

            break;
          case 401:
            result.status = "success";
            result.msg = "Unauthorized! Please log in again.";
            result.data = [];

            break;
          case 403:
            result.status = "success";
            result.msg =
              "Forbidden! You don't have permission to access this resource.";
            result.data = [];

            break;
          case 404:
            result.status = "success";
            result.msg = "Resource not found! Please check the URL.";
            result.data = [];

            break;
          case 500:
            result.status = "success";
            result.msg = "Server error! Please try again later.";
            result.data = [];

            break;
          default:
            result.status = "success";
            result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
            result.data = [];
        }
      } else {
        result.status = "error";
        result.msg = "Network error! Please check your connection.";
      }
    }
    return result;
  };
};

export const getStockDetails = (id, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomers/product/getStockDetails/${id}/${locationid}`,
    };

    try{
    const response = await ProductInstance(config);
     
        if (response.data.status === "success") {
          result.status = "success";
          result.msg = response.data.msg;
          result.data = response.data.data;
          dispatch(productActions.setStockListHandler(response.data.data));
        }
    }
      catch (error) {
        if (error.response ) {
          const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            result.status = "success";
            result.msg = "Bad Request! Please check your input parameters.";
            result.data = [];

            break;
          case 401:
            result.status = "success";
            result.msg = "Unauthorized! Please log in again.";
            result.data = [];

            break;
          case 403:
            result.status = "success";
            result.msg =
              "Forbidden! You don't have permission to access this resource.";
            result.data = [];

            break;
          case 404:
            result.status = "success";
            result.msg = "Resource not found! Please check the URL.";
            result.data = [];

            break;
          case 500:
            result.status = "success";
            result.msg = "Server error! Please try again later.";
            result.data = [];

            break;
          default:
            result.status = "success";
            result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
            result.data = [];
        }
      } else {
        result.status = "error";
        result.msg = "Network error! Please check your connection.";
      }
      }
    return result;
  };
};


export const getProductBatchDetails = (id, locationid, productpkid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomers/variant/get-variant-data/${id}/${locationid}/${productpkid}`,
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.msg = response.data.msg;
          result.data = response.data.data;
        }
      })
      .catch(function (error) {
        if (
          error.response &&
           error.response.status === 500
        ) {
          result.status = "error";
            result.msg = "Server error! Please try again later.";
        }
      });
    return result;
  };
};