import { createSlice } from "@reduxjs/toolkit";

const shipSlice = createSlice({
  name: "ship",
  initialState: {
    address: {},
    localityList:[],
    stationList:[]
  },
  reducers: {
    setShippingAddressHandler(state, action) {
      state.address = action.payload;
    },
    setLocalityList(state, action){
      state.localityList = action.payload
    },
    setStationList(state, action)
    {
      state.stationList = action.payload
    }
  },
});

export const shipActions = shipSlice.actions;

export default shipSlice;
