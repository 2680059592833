import { DateFormatDDmmYYYY } from "../../GlobalFunctions/Global";
import { CustomerInstance, OrderInstance } from "../../Router/AxiosInstance";
// import { orderListActions } from "./OrderListSlice";

export const AddOrderListHandler = (B2BauthToken, orderdetail) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/orderlist/addOrderList`,
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      },
      data: orderdetail,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });

    return result;
  };
};

export const OrderListHandler = (B2BauthToken, storeid, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      },
      url: `/api/b2bCustomer/orderlist/${storeid}/${locationid}`,
    };

    try {
      const response = await CustomerInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.message;
        result.data = response.data.data;
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 500)
      ) {
        result.status = "success";
        result.msg = "OrderList is empty";
        result.data = [];
      }
    }

    return result;
  };
};

export const UpdateOrderListHandler = (B2BauthToken, order) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: "",
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      },
      url: `/api/b2bCustomer/orderlist/updateQuantity`,
      data: order,
    };

    try {
      const response = await CustomerInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.message;
        result.data = response.data.data;
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
         error.response.status === 500
      ) {
        result.status = "error";
          result.msg = "Server error! Please try again later.";
      }
    }

    return result;
  };
};

export const EmptyOrderListHandler = (B2BauthToken, order) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: "",
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      },
      url: `/api/b2bCustomer/orderlist/deleteOrderlist`,
      data: order,
    };

    try {
      const response = await CustomerInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.message;
        result.data = response.data.data;
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
         error.response.status === 500
      ) {
        result.status = "error";
          result.msg = "Server error! Please try again later.";
      }
    }

    return result;
  };
};

export const DeleteOrderListItemHandler = (
  B2BauthToken,
  storeid,
  productid
) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: "",
    };

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      },
      url: `/api/b2bCustomer/orderlist/${storeid}/lineitem/${productid}`,
    };

    try {
      const response = await CustomerInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.message;
        result.data = response.data.data;
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
         error.response.status === 500
      ) {
        result.status = "error";
          result.msg = "Server error! Please try again later.";
      }
    }

    return result;
  };
};

export const createOrderHandler = (data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: {},
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/create-b2b-order/b2b`,
      data: data,
    };

    try{

      const response = await OrderInstance(config);
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      }
      catch(error) {
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              result.status = "success";
              result.msg = "Bad Request! Please check your input parameters.";
              result.data = {};
  
              break;
            case 401:
              result.status = "success";
              result.msg = "Unauthorized! Please log in again.";
              result.data = {};
  
              break;
            case 403:
              result.status = "success";
              result.msg =
                "Forbidden! You don't have permission to access this resource.";
              result.data = {};
  
              break;
            case 404:
              result.status = "success";
              result.msg = "Resource not found! Please check the URL.";
              result.data = {};
  
              break;
            case 500:
              result.status = "success";
              result.msg = "Server error! Please try again later.";
              result.data = {};
  
              break;
            default:
              result.status = "success";
              result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
              result.data = {};
          }
        } else {
          result.status = "error";
          result.msg = "Network error! Please check your connection.";
        }
      };
    return result;
  };
};

export const OrderHistoryHandler = (
  B2BauthToken,
  id,
  locationid,
  fromDate,
  toDate,
  filterStatus
) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    const newFromDate = DateFormatDDmmYYYY(fromDate);
    const newToDate = DateFormatDDmmYYYY(toDate);

    if (filterStatus === null || filterStatus === "all") {
      filterStatus = "";
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      },
      // url: `/api/b2bCustomer/fetch-order-history/${id}?fromDate=${newFromDate}&toDate=${newToDate}&LocationId=${locationid}&status=${filterStatus}`,
      url: `/api/b2bCustomer/fetch-erp-order-history/${id}?fromDate=${newFromDate}&toDate=${newToDate}&LocationId=${locationid}&status=${filterStatus}`,
    };
    try {
      const response = await OrderInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.message;
        result.data = response.data.data;
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            result.status = "success";
            result.msg = "Bad Request! Please check your input parameters.";
            result.data = [];

            break;
          case 401:
            result.status = "success";
            result.msg = "Unauthorized! Please log in again.";
            result.data = [];

            break;
          case 403:
            result.status = "success";
            result.msg =
              "Forbidden! You don't have permission to access this resource.";
            result.data = [];

            break;
          case 404:
            result.status = "success";
            result.msg = "Resource not found! Please check the URL.";
            result.data = [];

            break;
          case 500:
            result.status = "success";
            result.msg = "Server error! Please try again later.";
            result.data = [];

            break;
          default:
            result.status = "success";
            result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
            result.data = [];
        }
      } else {
        result.status = "error";
        result.msg = "Network error! Please check your connection.";
      }
    }
    return result;
  };
};

export const updateOrderStatus = (B2BauthToken, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: "",
    };
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      },
      url: `/api/b2bCustomer/update-tracking-status`,
    };
    try {
      const response = await OrderInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.message;
        result.data = response.data.data;
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            result.status = "success";
            result.msg = "Bad Request! Please check your input parameters.";
            result.data = "";

            break;
          case 401:
            result.status = "success";
            result.msg = "Unauthorized! Please log in again.";
            result.data = "";

            break;
          case 403:
            result.status = "success";
            result.msg =
              "Forbidden! You don't have permission to access this resource.";
            result.data = "";

            break;
          case 404:
            result.status = "success";
            result.msg = "Resource not found! Please check the URL.";
            result.data = "";

            break;
          case 500:
            result.status = "success";
            result.msg = "Server error! Please try again later.";
            result.data = "";

            break;
          default:
            result.status = "success";
            result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
            result.data = "";
        }
      } else {
        result.status = "error";
        result.msg = "Network error! Please check your connection.";
      } 
    }
    return result;
  };
};

export const OrderDetailsHandler = (id, pkid, fkid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/fetch-order-details/${id}?id=${pkid}&FkID=${fkid}`,
    };
    try {
      const response = await OrderInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.msg;
        result.data = response.data.data;
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            result.status = "success";
            result.msg = "Bad Request! Please check your input parameters.";
            result.data = "";

            break;
          case 401:
            result.status = "success";
            result.msg = "Unauthorized! Please log in again.";
            result.data = "";

            break;
          case 403:
            result.status = "success";
            result.msg =
              "Forbidden! You don't have permission to access this resource.";
            result.data = "";

            break;
          case 404:
            result.status = "success";
            result.msg = "Resource not found! Please check the URL.";
            result.data = "";

            break;
          case 500:
            result.status = "success";
            result.msg = "Server error! Please try again later.";
            result.data = "";

            break;
          default:
            result.status = "success";
            result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
            result.data = "";
        }
      } else {
        result.status = "error";
        result.msg = "Network error! Please check your connection.";
      }
    }
    return result;
  };
};

export const DeliveryDetailsHandler = (id, pkid, fkid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/fetch-delivery-status/${id}?PKID=${pkid}&SeriseId=${fkid}`,
    };
    try {
      const response = await OrderInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.msg;
        result.data = response.data.data;
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            result.status = "success";
            result.msg = "Bad Request! Please check your input parameters.";
            result.data = [];

            break;
          case 401:
            result.status = "success";
            result.msg = "Unauthorized! Please log in again.";
            result.data = [];

            break;
          case 403:
            result.status = "success";
            result.msg =
              "Forbidden! You don't have permission to access this resource.";
            result.data = [];

            break;
          case 404:
            result.status = "success";
            result.msg = "Resource not found! Please check the URL.";
            result.data = [];

            break;
          case 500:
            result.status = "success";
            result.msg = "Server error! Please try again later.";
            result.data = [];

            break;
          default:
            result.status = "success";
            result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
            result.data = [];
        }
      } else {
        result.status = "error";
        result.msg = "Network error! Please check your connection.";
      }
    }
    return result;
  };
};


export const getAdminOrderDetails = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: "",
    };
    let config = {
      method: "get",
      maxBodyLength: Infinity,

      url: `/api/b2bCustomer/orderdetail/${id}`,
    };
    try {
      const response = await OrderInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        result.msg = response.data.message;
        result.data = response.data.data;
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            result.status = "success";
            result.msg = "Bad Request! Please check your input parameters.";
            result.data = "";

            break;
          case 401:
            result.status = "success";
            result.msg = "Unauthorized! Please log in again.";
            result.data = "";

            break;
          case 403:
            result.status = "success";
            result.msg =
              "Forbidden! You don't have permission to access this resource.";
            result.data = "";

            break;
          case 404:
            result.status = "success";
            result.msg = "Resource not found! Please check the URL.";
            result.data = "";

            break;
          case 500:
            result.status = "success";
            result.msg = "Server error! Please try again later.";
            result.data = "";

            break;
          default:
            result.status = "success";
            result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
            result.data = "";
        }
      } else {
        result.status = "error";
        result.msg = "Network error! Please check your connection.";
      }
    }
    return result;
  };
};
