import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import classes from "./Login.module.css";

import swilmart from "../../Assessts/Images/swilmart.png";
import Wallpaper from "../../Assessts/Images/LoginWallpaper.webp";

import Loader from "../../reusableComponents/Loader/Loader";

import { path } from "../../Router/Routes.js";

import {
  LoggedInHandler,
  resendOtpHandle,
  sendOtpHandle,
  verifyOtpHandle,
} from "../../Store/Auth/AuthActions";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [heading, setHeading] = useState("Welcome To SwilMart");
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  let length = 4;
  let combinedOtp;
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  const [showResendTimer, setShowResendTimer] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(30);
  const [resendCount, setResendCount] = useState(0);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const intervalRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showOtpInput && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [showOtpInput]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
    combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      setOtp(newOtp);
      verifyOtp();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePhoneSubmit = (event) => {
    event.preventDefault();
    sendOtp();
  };

  const resendOtp = () => {
    if (resendCount < 2) {
      startResendTimer();
      setResendCount(resendCount + 1);
      dispatch(resendOtpHandle({ mobile: phoneNumber })).then((result) => {
        if (result.status === "success") {
          toast.success("OTP sent successfully!",{
            autoClose:false
          });
        }
      });
    } else {
      setShowTryAgain(true);
      setShowResendTimer(false);
    }
  };

  const startResendTimer = () => {
    setShowResendTimer(true);
    setResendCountdown(30);
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setResendCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          setShowResendTimer(false);
          clearInterval(intervalRef.current);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const sendOtp = () => {
    const data = {
      mobile: phoneNumber,
    };

    if (data.mobile.length === 10) {
      if (data.mobile) {
        setLoading(true);

        dispatch(sendOtpHandle(data)).then((result) => {
          if (result.status === "success") {
            setLoading(false);
            setHeading("Verify Your Mobile Number");
            setShowOtpInput(true);
            startResendTimer();
          }
           else if (result.status === "error") {
            toast.error("Something is Wrong, Please try again later.", {
              autoClose: false,
            });
          }
          setLoading(false);
        });
      } else {
        toast.error("Please enter mobile number", {
          position: "top-center",
        });
      }
    } else {
      toast.error("Please enter a valid number!", {
        position: "top-center",
      });
    }
  };

  const verifyOtp = () => {
    const data = {
      ContactNumber: phoneNumber,
      otp: combinedOtp,
    };
    dispatch(verifyOtpHandle(data)).then((result) => {
      if (result.status === "success") {
        const Token = result.data.token;
        localStorage.setItem("justLoggenIn", true);
        localStorage.setItem("B2BAuthToken", Token);
        dispatch(LoggedInHandler(Token)).then((result) => {
          if (result.status === "success") {
            navigate(path.storeList, { state: { showSignup: true } });
            return;
          }
         
        });
      } else {
        toast.error("Invalid OTP!", {
          position: "top-left",
          style: {
            background: "red",
            color: "#fff",
          },
        });
      }
    });
  };

  useEffect(() => {
    if (B2BauthToken) {
      dispatch(LoggedInHandler(B2BauthToken)).then((result) => {
        if (result.status === "success") {
          navigate(path.storeList);
        }
      });
    }
  }, [B2BauthToken, dispatch, navigate]);

  return (
    <div className={`${classes.LoginWrapper}`}>
      <div className={`${classes.LoginContent}`}>
        <div className={`${classes.LoginHeading}`}>
          <img src={swilmart} alt="logo" />
          <h1>{heading}</h1>
        </div>

        <div className={`${classes.LoginContainer}`}>
          {!showOtpInput ? (
            <>
              <h1 className={`${classes.LoginHeadText}`}>Login or SignUp</h1>
              <form onSubmit={handlePhoneSubmit}>
                <label className={`${classes.LabelClass}`}>Mobile Number</label>
                <input
                  type="tel"
                  maxLength="10"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  placeholder="Your Mobile Number"
                  className={classes.Input}
                  onInput={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.target.value)) {
                      e.target.value = "";
                    }
                  }}
                />
                <button type="submit" className={classes.SubmitButton}>
                  {loading === true ? <Loader /> : "Login"}
                </button>
              </form>
            </>
          ) : (
            <>
              <h1 className={`${classes.LoginHeadText}`}>OTP Verification</h1>
              <div className={`${classes.LoginOTPContainer}`}>
                <p>Enter the verification code</p>
                <div className={`${classes.OtpInputWrapper}`}>
                  <div className={`${classes.OtpInputContainer}`}>
                    {otp.map((value, index) => {
                      return (
                        <input
                          key={index}
                          type="text"
                          ref={(input) => (inputRefs.current[index] = input)}
                          value={value}
                          onChange={(e) => handleChange(index, e)}
                          onClick={() => handleClick(index)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          className={`${classes.OtpInput}`}
                        />
                      );
                    })}
                  </div>
                  {showResendTimer && (
                    <div className={`${classes.VerificationResendLink}`}>
                      00: {String(resendCountdown).padStart(2, "0")}
                    </div>
                   )} 
                  {!showResendTimer && (
                    <p
                      className={`${classes.VerificationResendLink}`}
                      onClick={resendOtp}
                    >
                      Resend OTP
                    </p>
                  )}
                  {showTryAgain && <div>Try again later</div>}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={`${classes.LoginCopy}`}>
          <p>
            By continuing, you agree with our <span>Privacy Policy</span> and{" "}
            <span>Terms and Conditions</span>
          </p>
        </div>
      </div>

      <div className={`${classes.LoginImage}`}>
        <img src={Wallpaper} alt="login-page" />
      </div>
    </div>
  );
};

export default Login;
