import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "./NotFound.module.css";

import { path } from "../../Router/Routes.js";


const NotFound = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className={`${classes.notfounddiv}`}>
        <div>
        <h1>404</h1>
        <h6>The page could not be found</h6>
        </div>
        <button onClick={()=>navigate(path.storeList)}>
          Back to Stores
        </button>
      </div>
      
    </>
  );
};

export default NotFound;
