import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import styles from "./StoreList.module.css";
import classes from "../productDetail/ProductDetail.module.css";

import NoStore from "../../Assessts/Images/NoStore.png";

import { path } from "../../Router/Routes.js";

const StoreLocationsModal = ({
  locationpopup,
  closeSummaryModal,
  locations,
  storelocid,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        className={`${classes.orderSummaryModal} order-summary-box store-location-box`}
        show={locationpopup}
        onHide={closeSummaryModal}
        centered
      >
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={classes.modalTitle}>
            Select location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.ModalPopupBody}>
          {locations && locations.length > 0 ? (
            <>
              <div className={` ${styles.StorelocTable}`}>
                <table className={`${styles.TableLocComponent}`}>
                  <thead className={`${styles.TableLocComponentHeader}`}>
                    <tr>
                      <th className={`${styles.TableLocNum}`} scope="col">
                        BRANCH NAME
                      </th>

                      <th className={`${styles.TableLocNum}`} scope="col">
                        ADDRESS
                      </th>

                      <th className={`${styles.TableLocNum}`} scope="col">
                        EMAIL
                      </th>
                      <th className={`${styles.TableLocNum}`} scope="col">
                        MOBILE NUMBER
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.map((store, index) => (
                      <tr
                        className={`${styles.tableRow}`}
                        key={index}
                        onClick={() =>
                          navigate(
                            path.productDetail
                              .replace(":id", storelocid)
                              .replace(":locationid", store.LocationId)
                          )
                        }
                      >
                        <td className={`${styles.TableNum}`}>
                          {store.LocationName}
                        </td>

                        <td className={`${styles.TableNum}`}>
                          {store.address}
                        </td>

                        <td className={`${styles.TableNum}`}>{store.email}</td>
                        <td className={`${styles.TableNum}`}>{store.phone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className={`${styles.NoImageContainer}`}>
              <img src={NoStore} alt="no-store-found" />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoreLocationsModal;
