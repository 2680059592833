import { toast } from "react-toastify";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import classes from "./Order.module.css";
import "./order.css";

import {
  createNewShippingAddress,
  getShippingAddressList,
} from "../../Store/ShippingAddress/ShippingAddressActions";

const AddAddressModal = ({ show, onClose, setShippingAddresses }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const localityList = useSelector((state) => state.address.localityList);
  const profileData = useSelector((state) => state.orderList.profileData);
  const stationList = useSelector((state) => state.address.stationList);

  const [newAddress, setNewAddress] = useState({
    Name: "",
    Address: "",
    Station: "",
    State: "",
    Locality: "",
    Pincode: "",
    Phone1: "",
    Phone2: "",
    Email: "",
    Latitude: "",
    Longitude: "",
    FKLocalityID: 0,
    FKStationID: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleStationChange = (e) => {
    e.preventDefault();
    const selectedStation = stationList.find(
      (station) => station.Station === e.target.value
    );

    setNewAddress((prev) => ({
      ...prev,
      Station: e.target.value,
      FKStationID: selectedStation ? selectedStation.PKID : 0,
    }));
  };

  const handleLocalityChange = (e) => {
    e.preventDefault();
    const selectedLocality = localityList.find(
      (station) => station.Locality === e.target.value
    );

    setNewAddress((prev) => ({
      ...prev,
      Locality: e.target.value,
      FKLocalityID: selectedLocality ? selectedLocality.PKID : 0,
    }));
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    if (!newAddress.Name.trim()) {
      toast.error("Name is required.");
      return;
    }
    if (!newAddress.Address.trim()) {
      toast.error("Address is required!");
      return;
    }

    dispatch(
      createNewShippingAddress(id, profileData.customerId, newAddress)
    ).then((result) => {
      if (
        result.status === "success" &&
        result.message === "Address saved successfully"
      ) {
        dispatch(getShippingAddressList(id, profileData.customerId)).then(
          (result) => {
            if (result.status === "success") {
              setShippingAddresses(result.data.lstShippingAddress);

              onClose();
            }
          }
        );
        toast.success(result.message);
      } else {
        toast.error(result.message);
        // onClose();
      }
    });
  };

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setNewAddress((prevState) => ({
            ...prevState,
            Latitude: latitude.toString(),
            Longitude: longitude.toString(),
          }));
        },
        (error) => {
          console.error("Error getting location:", error);
          toast.error(
            "Failed to retrieve location. Please check your location settings."
          );
        }
      );
    } else {
      toast.error("Geolocation is not available in your browser.");
    }
  };

  return (
    <>
      {show && <div className={classes.customOverlay}></div>}

      <Modal
        show={show}
        onHide={onClose}
        centered
        className={classes.AddAddressModal}
      >
        <Modal.Body className={`${classes.addressbody} addressform`}>
          <form>
            <div className={` my-3 `}>
              <label htmlFor="name" className={classes.formLable}>
                Enter Name
              </label>
              <input
                id="name"
                type="text"
                name="Name"
                className={`${classes.formtext} form-control`}
                value={newAddress["Name"]}
                onChange={handleInputChange}
              />
            </div>

            <div className={` mt-3 position-relative`}>
              <label htmlFor="address" className={classes.formLable}>
                Enter Address
              </label>
              <textarea
                id="address"
                name="Address"
                className={`${classes.formtext} form-control`}
                value={newAddress["Address"]}
                onChange={handleInputChange}
              />
            </div>

            <div className="row mt-3">
              <div className="col-6 mb-3 ">
                <label className={classes.formLable}>Select Station</label>
                <select
                  className={`${classes.AddressSelect} form-select`}
                  value={newAddress["station"]}
                  onChange={(e) => handleStationChange(e)}
                >
                  <option value="">Select Station</option>
                  {stationList.map((station) => (
                    <option key={station.PKID} value={station.Station}>
                      {station.Station}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6 mb-3 position-relative">
                <label className={classes.formLable}>Select Locality</label>
                <select
                  name={"locality"}
                  className={`${classes.AddressSelect} form-select`}
                  value={newAddress["locality"]}
                  onChange={(e) => handleLocalityChange(e)}
                >
                  <option value="">Select Locality</option>
                  {localityList.map((locality) => (
                    <option key={locality.PKID} value={locality.Locality}>
                      {locality.Locality} - {locality.Area}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6 mb-3 position-relative">
                <label htmlFor="state" className={classes.formLable}>
                  Enter State
                </label>

                <input
                  id="state"
                  type="text"
                  name="State"
                  className={`${classes.formtext} form-control`}
                  value={newAddress["State"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-6 mb-3 position-relative">
                <label htmlFor="pincode" className={classes.formLable}>
                  Enter Pincode
                </label>

                <input
                  id="pincode"
                  type="text"
                  name="Pincode"
                  className={`${classes.formtext} form-control`}
                  value={newAddress["Pincode"]}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6 mb-3 position-relative">
                <label htmlFor="phone" className={classes.formLable}>
                  Enter Phone
                </label>

                <input
                  id="phone"
                  type="text"
                  name="Phone1"
                  className={`${classes.formtext} form-control`}
                  value={newAddress["Phone1"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-6 mb-3 position-relative">
                <label htmlFor="mobile" className={classes.formLable}>
                  Enter Mobile
                </label>

                <input
                  id="mobile"
                  type="text"
                  name="Phone2"
                  className={`${classes.formtext} form-control`}
                  value={newAddress["Phone2"]}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6 mb-3 position-relative">
                <label htmlFor="email" className={classes.formLable}>
                  Enter Email
                </label>

                <input
                  id="email"
                  type="text"
                  name="Email"
                  className={`${classes.formtext} form-control`}
                  value={newAddress["Email"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className={`${classes.LocationDiv} col-6 mb-3`}>
                <label htmlFor="cordinates" className={classes.formLable}>
                  Enter Lat, Long
                </label>

                <input
                  id="cordinates"
                  type="text"
                  name="Latitude"
                  className={`${classes.formtext} ${classes.locationINput} form-control`}
                  onChange={handleInputChange}
                  value={`${newAddress.Latitude}, ${newAddress.Longitude}`}
                  readOnly
                />

                <i
                  className="bi bi-geo-alt-fill"
                  title="get lat long"
                  onClick={getLocation}
                ></i>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => handleAddAddress(e)} className={classes.Btn}>
            Save Address
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddAddressModal;
