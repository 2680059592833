import React from "react";
import styles from "./StoreList.module.css";
import SwilUnifyBaner from "../../Assessts/Images/SwilUnifyBanner.png";

const SwilUnifyBannerComponent = ({closeBanner}) => {
    const redirect = ()=>{
        window.open("https://swilunify.com/#/signup", "_blank", "noopener noreferrer");
    }
  return (
    <div className={styles.bannerComponent}>
      <div className={styles.bannerContent}>
        <div className={styles.bannerBody}>
          <img
            src={SwilUnifyBaner}
            className={styles.bannerImage}
            alt="swilunifybanner"
            onClick={redirect}
          />
          <div className={styles.closeBanner} onClick={closeBanner}>
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwilUnifyBannerComponent;
