import React, { useState } from "react";
import offcss from "../../reusableComponents/SideDrawer/SideDrawer.module.css";
import classes from "./Order.module.css";
import { Offcanvas, Table } from "react-bootstrap";

const OrderDetailsOffcanvas = ({
  show,
  handleClose,
  selectedOrder,
  orderFlag,
  deliveryDetails,
}) => {
  const [isTracking, setIsTracking] = useState(false);

  const finalStatus = deliveryDetails[0]?.nextStatusCategory;
  const currentStatus = deliveryDetails[0];

  const shippingAddressArray =
    !orderFlag && selectedOrder?.ShippingAddressRemark
      ? JSON.parse(selectedOrder?.ShippingAddressRemark)
      : [];

  const handleTrackOrder = () => {
    setIsTracking(!isTracking);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Add leading zero if needed
    const isAM = hours < 12;

    // Convert to 12-hour format and add AM/PM
    hours = hours % 12 || 12; // Converts 0 to 12 for 12 AM
    const ampm = isAM ? "AM" : "PM";

    return `${day}/${month}/${year} ${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;
  };

  return (
    <>
      <Offcanvas
        className="w-50"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <div>
          <div className={`${classes.OffcanvasHeader} offcanvas-header`}>
            <h5
              className={`${classes.OffcanvasHeaderTitle} offcanvas-title`}
              id="offcanvasExampleLabel"
            >
              Order ID: {selectedOrder?.GRNo}
            </h5>
            {orderFlag ? (
              ""
            ) : (
              <button
                type="button"
                className={`${classes.OffcanvasButton}`}
                onClick={handleTrackOrder}
              >
                {!isTracking ? (
                  <>
                    Track Order <i class="bi bi-geo-alt"></i>
                  </>
                ) : (
                  <> Order Details</>
                )}
              </button>
            )}
          </div>
          <div className={`${classes.OffcanvasDate}`}>
            <p className={`${classes.OrderDateHeading}`}>
              Order Date:
              <span>
                {" "}
                {new Date(selectedOrder?.GRDate)
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, "-")}{" "}
              </span>
            </p>
          </div>
        </div>

        <div class="offcanvas-body">
          {!isTracking ? (
            <Table className={classes.DetailsTable} striped bordered hover>
              <thead>
                <tr>
                  <th className={classes.firstcolWidth}>#</th>
                  <th>Product Name</th>
                  <th className={classes.colWidth}>Quantity</th>
                  <th className={classes.RateCol}>Rate</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrder?.GetByIdTransDetail &&
                  selectedOrder?.GetByIdTransDetail.length > 0 &&
                  selectedOrder?.GetByIdTransDetail.map((product, index) => {
                    return (
                      <tr key={index}>
                        <td className={classes.firstcolWidth}>{index + 1}</td>
                        <td>
                          {orderFlag
                            ? product?.Product_name
                            : product?.NameToPrint}
                        </td>
                        <td className={classes.colWidth}>{product?.Qty}</td>
                        <td className={classes.RateCol}>
                          {product?.Rate.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            <div className="progress-bar-container">
              {currentStatus &&
                currentStatus.nextStatusCategory &&
                finalStatus && (
                  <div className="step">
                    <div
                      className="circle circle-gray"
                      style={{
                        backgroundColor: `#${Math.abs(
                          currentStatus.nextStatusColor
                        ).toString(16)}`,
                      }}
                    ></div>
                    <div className="status-label text-muted">{finalStatus}</div>
                    {/* {currentStatus.DATE_MODIFIED && (
                      <div className="status-date">
                        {formatDate(currentStatus.DATE_MODIFIED)}
                      </div>
                    )} */}
                  </div>
                )}

              {deliveryDetails.map((step, index) => {
                const isActive = index <= deliveryDetails.length - 1;

                return (
                  <div className="step" key={step.PKStatusID}>
                    {finalStatus && index === 0 && (
                      <div className="line line-grey"></div>
                    )}

                    <div
                      className={`circle ${isActive ? "circle-active" : ""}`}
                      style={{
                        backgroundColor: isActive
                          ? `#${Math.abs(step.StatusColor).toString(16)}`
                          : "#ddd",
                      }}
                    ></div>
                    <div className="status-label">{step.Status}</div>
                    {step.DATE_MODIFIED && (
                      <div className="status-date">
                        {formatDate(step.DATE_MODIFIED)}
                      </div>
                    )}

                    {index < deliveryDetails.length - 1 && (
                      <div
                        className={`line ${isActive ? "line-active" : ""}`}
                      ></div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className={`${offcss.CheckoutBody}`}>
          <div className={`${offcss.BillBody}`}>
            <div className={`${offcss.Address}`}>
              <h5 className="mb-1">
                <span className="me-1">
                  <i className="bi bi-geo-alt-fill text-warning"></i>
                </span>
                Delivery Address
              </h5>
              {orderFlag ? (
                <>
                  <p>{selectedOrder?.ShippingAddressRemark?.Street1}</p>
                  <p>{selectedOrder?.ShippingAddressRemark?.Street2}</p>
                  <p>
                    {selectedOrder?.ShippingAddressRemark?.City +
                      ", " +
                      selectedOrder?.ShippingAddressRemark?.State +
                      ", " +
                      selectedOrder?.ShippingAddressRemark?.Country}
                  </p>
                </>
              ) : (
                <>
                  {shippingAddressArray?.map((address, index) => (
                    <p key={index}>{address.ShippingAddress}</p>
                  ))}{" "}
                </>
              )}
            </div>

            {orderFlag ? (
              <>
                <p>Please wait for order confirmation from the admin</p>
              </>
            ) : (
              <>
                <div className={`${offcss.Price}`}>
                  <h3>Order Number</h3>
                  <p>{selectedOrder?.Series + " " + selectedOrder?.EntryNo}</p>
                </div>

                <div className={`${offcss.TotalAmount}`}>
                  <h3>Status</h3>
                  <p> {selectedOrder?.DeliveryStatus}</p>
                </div>
                <div className={`${offcss.TotalAmount}`}>
                  <h3>Gross Amount</h3>
                  <p> {selectedOrder?.GrossAmt.toFixed(2)}</p>
                </div>
                <div className={`${offcss.TotalAmount}`}>
                  <h3>Tax Amount</h3>
                  <p> {selectedOrder?.TaxAmt.toFixed(2)}</p>
                </div>
                <div className={`${offcss.TotalAmount}`}>
                  <h3>Total Amount</h3>
                  <p> {selectedOrder?.NetAmt.toFixed(2)}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default OrderDetailsOffcanvas;
