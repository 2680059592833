import React from "react";

const DeliveryStatus = ({ basicModal, toggleShow, order, deliveryDetails }) => {
  const reversedData = [...deliveryDetails].reverse();

  // Extract the final status from the first element of the original array
  const finalStatus = deliveryDetails[0]?.nextStatusCategory;
  return (
    <>
      {basicModal && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div
              className="modal-content "
              style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}
            >
              <div className="modal-header border-bottom-0">
                <button
                  type="button"
                  className="btn-close "
                  aria-label="Close"
                  onClick={toggleShow}
                ></button>
              </div>
              <div className="modal-body text-start px-4 pt-0 pb-4">
                <div className="text-center">
                  <h5 className="mb-3">Order Status</h5>
                  <h5 className="mb-5">Ref Number: {order?.GRNo}</h5>
                </div>

                {/* <div className="d-flex justify-content-between mb-5">
                  <div className="text-center">
                    <div className="circle"></div>
                    <p>Order placed</p>
                  </div>
                  <div className="text-center">
                    <div className="circle"></div>
                    <p>In Transit</p>
                  </div>
                  <div className="text-center">
                    <div className="circle"></div>
                    <p className="lead fw-normal">Out for Delivery</p>
                  </div>
                  <div className="text-center">
                    <div className="circle circle-gray"></div>
                    <p className="text-muted">Delivered</p>
                  </div>
                </div> */}

                <div className="d-flex justify-content-between mb-5">
                  {reversedData.map((step, index) => (
                    <div key={step.PKStatusID} className="text-center">
                      <div
                        className="circle"
                        style={{
                          backgroundColor: `#${Math.abs(
                            step.StatusColor
                          ).toString(16)}`,
                        }}
                      ></div>

                      <p>{step.StatusCategory}</p>
                    </div>
                  ))}

                  <div className="text-center">
                    <div className="circle circle-gray"></div>
                    <p className="text-muted">{finalStatus}</p>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-4 text-center">
                    <p className="lead fw-normal">Shipped with</p>
                  </div>
                  <div className="col-md-4 text-center">
                    <p className="lead fw-normal">UPS Expedited</p>
                  </div>
                  <div className="col-md-2 text-center">
                    <i className="bi bi-telephone"></i>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-4 text-center">
                    <p className="lead fw-normal">Estimated Delivery</p>
                  </div>
                  <div className="col-md-4 text-center">
                    <p className="lead fw-normal">02/12/2017</p>
                  </div>
                  <div className="col-md-2 text-center">
                    <i className="bi bi-envelope"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeliveryStatus;
